import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import filters from "./filters";
import "tailwindcss/tailwind.css";
import VueGtag from "vue-gtag-next";

const app = createApp(App)
  .use(store)
  .use(router)
  .use(VueGtag, {
    property: {
      id: "UA-188674373-1",
    },
  });

app.config.globalProperties.$filters = filters;
app.mount("#app");
