<template>
  <app-container>
    <router-view />
  </app-container>
</template>

<script>
import Container from "@/components/layout/Container";

export default {
  components: {
    "app-container": Container,
  },
};
</script>
