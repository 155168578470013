import { createRouter, createWebHistory } from "vue-router";
import { trackRouter } from "vue-gtag-next";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

trackRouter(router);

export default router;
