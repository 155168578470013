<template>
  <div class="min-h-screen bg-white">
    <main class="pb-8">
      <div
        class="max-w-3xl px-4 py-10 mx-auto sm:px-6 sm:py-12 lg:max-w-4xl lg:py-16 xl:max-w-6xl"
      >
        <slot></slot>
      </div>
    </main>
  </div>
</template>

<script>
export default {};
</script>
